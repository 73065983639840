@import "src/styles/shared";

@media (orientation: landscape) {
  .screensaver {
    width: 100% !important;
  }

  .header {
    width: 33vw !important;
    right: 0;
    height: 200px;
    position: fixed;
    z-index: 500;
    display: flex;
    justify-content: center;
    pointer-events: none;
    user-select: none;

    .logo {
      width: 100%;
      height: 100%;
      pointer-events: auto;
      cursor: pointer;
      object-fit: contain;
    }
  }


  .logo-screensaver {
    height: 150px;
    position: fixed;
    right: 30px;
    top: 0;
    width: auto;

    pointer-events: auto;
    cursor: pointer;
    object-fit: contain;
  }
}

@media (orientation: portrait) {
  .screensaver {
    width: 100% !important;
  }

  .logo-screensaver {
    width: 100%;
    max-width: 100%;
    padding-right: 81px;
    padding-left: 81px;
    padding-top: 28px;
    pointer-events: auto;
    cursor: pointer;
    object-fit: fill;
  }

  .header {
    width: 100%;
    max-width: 100%;
    z-index: 500;
    display: flex;
    justify-content: center;
    pointer-events: none;
    user-select: none;

    .logo {
      width: 151px;
      max-width: 151px;
      margin-top: 20px;
      pointer-events: auto;
      cursor: pointer;
      object-fit: fill;
    }
  }
}

@keyframes topLeft {
  from {
    transform: translate(-150%, -50%);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes topRight {
  from {
    transform: translate(50%, -50%);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes left {
  from {
    transform: translate(-150%);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}