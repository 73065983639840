@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInAfter66 {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@media (orientation: portrait) {
  .toggle-icon {
    height: 50px;
    align-self: center;
    margin-right: 5px;
    padding-top: 2px;
    object-fit: contain;
    position: absolute;
    right: 0;
  }
  .navigation-controls {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
    justify-content: space-between;
    pointer-events: none;
    user-select: auto;
    z-index: 999;

    .dumb-space {
      height: 100%;
      flex-grow: 1;
    }

    img {
      height: 40px;
      cursor: pointer;
      align-self: center;
      object-fit: contain;
      pointer-events: auto;
      color: #076777;
    }
  }

  .demo {
    z-index: 100;
    pointer-events: none;
    user-select: none;


    //background-color: #ff00ff80; // TODO REMOVE DEBUG

    display: flex;
    justify-content: flex-end;
    flex-flow: column;
    flex-grow: 1;
    align-items: center;

    .controls {
      width: 100%;
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      justify-content: flex-end;

      .environment-choose {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 50px;
        flex-grow: 1;
        font-size: 30px;
        align-self: center;
        color: #454443;
        margin-bottom: 0;
        justify-content: flex-end;

        .buttons {
          height: 48px;
          display: flex;
          flex-flow: row;
          justify-content: space-between;
        }

        .environment-choose-label {
          font-size: 15px;
          text-align: center;
        }

        .button {
          margin-top: 0;
          padding-top: 0;
          width: 160px !important;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          cursor: pointer;
          pointer-events: auto;
          background: #F6F6F6 0 0 no-repeat padding-box;
          box-shadow: 2px 2px 6px #0000000D;
        }

        .button > p {
          font-size: 16px;
          font-weight: 600;
          padding: 0;
          margin: 0;
        }
      }
    }

    .drawer {
      width: 100%;
      margin: 0 0 -60px 0;
      padding: 0;
      z-index: 150;
      pointer-events: auto;
      background-color: #f6f6f6;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      //box-shadow: -5px -5px 10px -5px #999;
      box-shadow: 0 0 10px #999;
      display: flex;
      flex-flow: column;
      cursor: pointer;
      user-select: none;

      .drawer-border-trick {
        height: 60px;
        width: 100%;
      }

      .item-content {
        font-size: 15px;
        line-height: 15px;
        color: #454443;
        margin-top: 0;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-flow: column;

        p {
          margin-top: 10px;
          flex-grow: 1;

        }

        img {
          width: 100%;
          object-fit: contain;
        }

        br {
          display: block;
          margin: 5px;
          background: #70ad47;
          content: " ";
        }
      }

      .drawer-header {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-content: center;

        .title-line-break {
          display: none;
        }

        p {
          margin: 10px;
        }

        h1 {
          padding: 1rem;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 0;
          margin-top: 0;
          z-index: 50;
        }
      }
    }

    .footer {
      background-color: #f6f6f6;
      width: 100%;
      box-shadow: 0 0 10px #999;
      position: fixed;
      bottom: 0;
      padding: 1px 0 0;
      height: 60px !important;
      min-height: 60px !important;
      display: flex;
      z-index: 999 !important;
      cursor: cell !important;
      flex-flow: row;
      align-content: center;
      justify-content: center;

      * {
        flex-grow: 1;
        flex-basis: 0;
        min-height: 10px;
        min-width: 10px;
      }

      img {
        cursor: pointer;
        object-fit: contain;
        pointer-events: auto;
      }

      .nav-faded {
        opacity: 0.60;
      }
    }

    .carousel-hidden {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      display: none;
      height: 0;
    }

    .choose-item {
      font-size: 15px;
      margin: 0 0 0 20px;
    }

    .text-carousel-item {
      display: flex;
      flex-flow: column;
      cursor: pointer;
      font-size: 20px;
      margin: 0 10px 0 40px;
      padding: 0;

      .forward-slash-carousel-content {
        font-size: 27px;
        font-weight: 600;
      }
    }

    .carousel-content {
      display: flex;
      max-width: 100%;
      max-height: 30vh;
      overflow-y: scroll;
      margin-bottom: 10px;
      cursor: auto;

      .carousel-item {
        display: flex;
        flex-flow: row;
        max-width: 100%;
        width: 100%;
        cursor: pointer;

        img {
          display: none;
        }

        .carousel-label {
          display: flex;
          flex-flow: column;
          justify-content: space-around;
          border-radius: 1rem;
          box-shadow: -5px 5px 5px -5px #999;
          //padding: 15px;
          margin: 0 65px 0.5rem;
          flex-grow: 1;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          user-select: none;
          text-align: center;
        }
      }

      .drawer-current-selected-title {
        color: #454443;
        font-size: 20px;
        margin: 0;
        padding: 0 20px;
        font-weight: 600;

        .forward-slash-drawer-title {
          font-size: 27px;
          font-weight: 600;
        }
      }
    }

    .items {
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      color: #454443;
      width: 100%;
      padding-bottom: 2rem;
      background: linear-gradient(#ff5100, #ff5100) no-repeat center/1px 100%;

      .col-header {
        background-color: #454443;
        border: 2px solid #ff5100;
        color: #ffffff;
        border-radius: 1rem;
        margin-left: 10px;
        margin-right: 10px;
        font-size: large;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1rem;
      }

      .col-row {
        background-color: #454443;
        border: 1px solid #E3E3E3;
        color: #E3E3E3;
        border-radius: 1rem;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        margin-left: 30px;
        margin-right: 30px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 1rem;
        line-height: 1rem;
        height: 4rem;
        text-align: center;
      }
    }

    .spacer {
      flex-grow: 1;
      width: 100%;
    }
  }
  .accessory-view {
    width: 100%;
    margin-bottom: 60px;
  }
}


@media (orientation: landscape) {
  .hidden-landscape {
    display: none !important;
  }

  .navigation-controls {
    position: fixed;
    left: 30px;
    right: calc(33vw + 30px);
    top: 50vh;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    pointer-events: none;
    user-select: auto;
    z-index: 999;

    .dumb-space {
      flex-grow: 1;
    }

    img {
      cursor: pointer;
      pointer-events: auto;
    }

    p {
      font-size: 100px;
      cursor: pointer;
      pointer-events: auto;
      font-weight: bold;
      height: 35vh;
      color: #076777;
    }
  }

  .accessory-view {
    width: 33vw;
    height: calc(100vh - 260px);
    margin-bottom: 60px;
    display: flex;
    flex-flow: column;
    align-self: end;
    align-content: flex-end;
    justify-content: flex-end;
  }

  .demo {
    z-index: 100;
    position: fixed;
    right: 0;
    top: 200px;
    max-height: calc(100vh - 200px);
    background-color: #f6f6f6;
    box-shadow: 0 0 10px #999;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    flex-flow: column;
    width: 33vw;
    //overflow: scroll;

    .controls {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      .environment-choose {
        width: 100%;
        flex-grow: 1;
        max-width: 100%;
        font-size: 24px;
        color: #454443;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-top: 2rem;
        display: flex;
        flex-flow: column;
        align-self: flex-start;
        justify-content: center;

        p {
          align-self: center;
          margin-bottom: 3rem;
        }

        .buttons {
          align-self: center;
          display: flex;
          flex-flow: column;
        }

        .button {
          //margin-top: 0;
          height: 100%;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          cursor: pointer;
          pointer-events: auto;
          background: #F6F6F6 0 0 no-repeat padding-box;
          box-shadow: 2px 2px 6px #0000000D;

          margin-top: 20px;
        }

        p {
          margin: 22px 30px;
          font-size: 26px;
          line-height: 31px;
        }
      }
    }

    .drawer {
      width: 33vw;
      z-index: 150;
      pointer-events: auto;
      margin: 0 0 0 0;
      padding-left: 1rem;
      background-color: #f6f6f6;
      box-shadow: 0 0 10px #999;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      display: flex;
      flex-flow: column;
      cursor: pointer;
      user-select: none;

      .drawer-border-trick {
        display: none;
        //height: 60px;
        //width: 100%;
      }

      .drawer-header {
        display: flex;
        flex-flow: row;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        justify-content: space-between;
        align-content: center;
        flex-shrink: 0;
      }

      .drawer-current-selected-title {
        color: #454443;
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 30px;

        .forward-slash-drawer-title {
          font-size: 60px;
          font-style: italic;
          font-weight: 500;
          line-height: 55px;
          margin-left: 44px;
          margin-right: 10px;
        }
      }

      h1 {
        font-size: 34px;
        font-weight: 600;
        padding: 1rem 2rem;
        text-align: center;
        margin-bottom: 0;
        margin-top: 0;
        z-index: 50;
      }

      .item-content {
        font-size: 34px;
        line-height: 40px;
        color: #454443;
        margin-top: 0;

        img {
          max-width: 100%;
          width: 100%;
          object-fit: contain;
        }

        p {
          font-size: 22px;
          line-height: 30px;
          margin-top: 10px;
        }

        br {
          display: block;
          min-height: 0 !important;
          margin: 20px;
          content: " ";
        }
      }

      .toggle-icon {
        margin-right: 1rem;
        width: 3rem;
        object-fit: contain;
      }

      .choose-item {
        font-size: 24px;
        padding-bottom: 2rem;
      }
    }

    .footer {
      background-color: #f6f6f6;
      width: 33vw;
      box-shadow: 0 0 10px #999;
      position: fixed;
      bottom: 0;
      padding: 1px 0 0;
      height: 60px !important;
      min-height: 60px !important;
      display: flex;
      z-index: 999 !important;
      cursor: cell !important;
      flex-flow: row;
      align-content: center;
      justify-content: center;

      * {
        flex-grow: 1;
        flex-basis: 0;
        min-height: 10px;
        min-width: 10px;
      }

      img {
        cursor: pointer;
        object-fit: contain;
        pointer-events: auto;
      }

      .nav-faded {
        opacity: 0.6;
      }
    }

    .carousel-hidden {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      display: none;
      height: 0;
    }

    .text-carousel-item {
      display: flex;
      flex-flow: column;
      cursor: pointer;
      font-size: 32px;
      margin-top: 10px;

      p {
        font-size: 32px;
      }

      .forward-slash-carousel-content {
        font-size: 42px;
        margin-right: 0;
        margin-left: 44px;
        padding-right: 0;
        font-weight: 600;
      }
    }

    .carousel-maximized {
      //min-height: 50vh;
      flex-grow: 1;
    }

    .drawer-maximized {
      flex-grow: 1;
      max-height: calc(100vh - 405px); //crappy hack TODO fix it
    }

    .carousel-content {
      display: flex;
      justify-content: flex-start;
      cursor: auto;
      overflow-y: auto;
      flex-flow: column;
      width: 100%;
      max-width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;

      .carousel-item {
        display: flex;
        flex-flow: column;
        cursor: pointer;

        img {
      display: none;
      /*    height: 10rem;
          width: 10rem;
          margin-left: auto;
          margin-right: auto;
          object-fit: cover;
          margin-top: -1rem;*/
        }

        .carousel-label {
          display: flex;
          flex-flow: column;
          justify-content: space-around;
          border-radius: 1rem;
          box-shadow: -5px 5px 5px -5px #999;
          padding: 0.25rem 1rem;
          margin: 0.5rem 1rem;
          flex-grow: 1;
        }

        p {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          user-select: none;
        }
      }

    }

    .items {
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      color: #454443;
      width: 100%;
      padding-bottom: 1.5rem;
      background: linear-gradient(#ff5100, #ff5100) no-repeat center/1px 100%;

      .col-header {
        background-color: #454443;
        border: 2px solid #ff5100;
        color: #ffffff;
        border-radius: 1rem;
        margin-left: 10px;
        margin-right: 10px;
        font-size: medium;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0;
      }

      .col-row {
        background-color: #454443;
        border: 1px solid #E3E3E3;
        color: #E3E3E3;
        border-radius: 1rem;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        margin-left: 25px;
        margin-right: 25px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 1rem;
        line-height: 1rem;
        height: 4rem;
        text-align: center;
      }
    }

    .spacer {
      flex-grow: 1;
      width: 0;
      height: 0;
    }
  }
}

.animated-label {
  margin: 2rem 5rem 1rem;
  animation: 1s ease-out 0s slideInFromRight;
  font-size: large;
  user-select: none;
  font-weight: bold
}

.rotate-left {
  transform: rotate(90deg);
}