@import './variables';

.full-page {
  z-index: 900;
  user-select: none;

  background-color: rgba($fullpage-background, 1);
  color: white;
  font-size: 1.25rem;

  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  transition: opacity $transition-delay, background-color $transition-delay-slow; // TODO change delays

  &.flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.transparent {
    background-color: rgba(black, 0);
  }

  &.semi-transparent {
    background-color: rgba($fullpage-background, $fullpage-background-alpha);
  }

  & * {
    align-items: center;
  }
}

.visible {
  opacity: 1;
  pointer-events: auto;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  display: none;
}


.flex-1 {
  flex: 1;
  //border: solid green 1px;
  display: flex;
}

.flex-2 {
  flex: 2;
  //border: solid red 1px;
  display: flex;
}

.flex-3 {
  flex: 3;
  //border: solid yellow 1px;
  display: flex;
}

img {
  pointer-events: none;
}
