@import 'src/styles/shared';

.webgl-canvas {
  //background-color: transparent;


  background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(186,196,197,1) 100%);

  //display: inline-block;
  position: absolute; // needed otherwise WebGL canvas doesn't shrink when reducing height
  //flex-grow: 1;

  height: 100%;
  width: 100%;
  top: 0;

  //&.desktop {
  //
  //}
  //&.tablet {
  //
  //}
  //&.mobile {
  //
  //}

  transition: filter $transition-delay;
  &.blurred {
    filter: blur(10px);
  }
}

.stats, .stats > * {
  // uncomment below to dock Stats top-right
  top: 0 !important;
  left: unset !important;
  right: 0 !important;
  zoom: 1.5;
}