@import './mixins';

.icon-container {
  //width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;

  cursor: pointer;
}

.icon {
  @include icon-generator;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  width: $button-height;
  height: $button-height;
}
