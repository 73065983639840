//@font-face {
//  font-family: 'Bliss';
//  src:  url('./assets/fonts/BlissProExtraLight.otf') format('truetype');
//  font-weight: 200;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'Bliss';
//  src:  url('./assets/fonts/BlissProExtraLightItalic.otf') format('truetype');
//  font-weight: 200;
//  font-style: italic;
//}

@font-face {
  font-family: 'Bliss';
  src:  url('./assets/fonts/BlissProLight.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Bliss';
  src:  url('./assets/fonts/BlissProLightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bliss';
  src:  url('./assets/fonts/BlissProLight.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
//@font-face {
//  font-family: 'Bliss';
//  src:  url('./assets/fonts/BlissProItalic.otf') format('truetype');
//  font-weight: 400;
//  font-style: italic;
//}

//@font-face {
//  font-family: 'Bliss';
//  src:  url('./assets/fonts/BlissProMedium.otf') format('truetype');
//  font-weight: 500;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'Bliss';
//  src:  url('./assets/fonts/BlissProMediumItalic.otf') format('truetype');
//  font-weight: 500;
//  font-style: italic;
//}

@font-face {
  font-family: 'Bliss';
  src:  url('./assets/fonts/BlissProBold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Bliss';
  src:  url('./assets/fonts/BlissProBold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Bliss';
  src:  url('./assets/fonts/BlissProBoldItalic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

//@font-face {
//  font-family: 'Bliss';
//  src:  url('./assets/fonts/BlissProExtraBold.otf') format('truetype');
//  font-weight: 800;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'Bliss';
//  src:  url('./assets/fonts/BlissProExtraBoldItalic.otf') format('truetype');
//  font-weight: 800;
//  font-style: italic;
//}

//@font-face {
//  font-family: 'Bliss';
//  src:  url('./assets/fonts/BlissProHeavy.otf') format('truetype');
//  font-weight: 900;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'Bliss';
//  src:  url('./assets/fonts/BlissProHeavyItalic.otf') format('truetype');
//  font-weight: 900;
//  font-style: italic;
//}