.loading-feedback {
  z-index: 100;

  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;

  text-align: center;
  font-weight: 200;

  display: flex;
  flex-direction: column;
  justify-content: center;

  user-select: none;
  pointer-events: none;

  .circle {
    font-size: 2rem;
  }

  .text {
    font-size: 2rem;
  }
}