@media (orientation: portrait) {
  .app {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    flex-flow: column;
  }
}

@media (orientation: landscape) {
  .app {
    height: 100vh;
    align-items: flex-end;
    flex-flow: column;
  }
}