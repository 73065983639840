@media (orientation: portrait) {
  .screensaver-next {
    max-height: 76px;
    max-width: 76px;
    pointer-events: all;
    cursor: pointer;
    position: fixed;
    bottom: 24px;
    right: 28px;
  }

  .text-bottom {
    display: flex;
    flex-flow: column;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 40px;
    margin-bottom: 60px;
    object-fit: contain;

    .forward-slash {
      color: #ff5100;
      margin-right: 0.8rem;
      font-size: 50px;
      font-weight: 600;
    }

    h1 {
      font-size: 39px;
      font-weight: normal;
      margin-bottom: 4px;
    }

    p {
      font-size: 27px;
      color: #454443;
      margin-top: 0;
    }
  }
}

@media (orientation: landscape) {
  .screensaver {
    display: flex;
    flex-flow: row;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    justify-content: space-around;
    align-items: center;
  }

  .screensaver-next {
    right: 10vw;
    position: absolute;
    max-height: 76px;
    max-width: 76px;
    pointer-events: all;
    cursor: pointer;
  }

  .text-bottom {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    //padding: 20rem 20rem 3rem;
    object-fit: contain;

    .forward-slash {
      color: #ff5100;
      margin-right: 2rem;
      font-size: 130px;
      font-weight: 600;
    }

    h1 {
      font-size: 100px;
      font-weight: 400;
      margin: 0;
    }

    p {
      font-size: 70px;
      color: #454443;
      margin-top: 0;
    }
  }
}

.screensaver {
  z-index: 50;
  user-select: none;

  position: fixed;
  width: fit-content;


  .touch-screen {
    position: fixed;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 20rem;
    }

    span.spacer:first-child {
      flex: 1 1 80%;
    }

    span.spacer:last-child {
      flex: 1 1 20%;
    }
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInAfter66 {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeBackground {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #FFFFFF80;
  }
}
