@import './variables';

@mixin transition($x...) {
  -webkit-transition: $x;
  transition: $x;
}

// Icons class class generator
@mixin icon-generator {
  @each $icon in $icons {
    &.icon-#{$icon} {
      background-image: url('~assets/icons/#{$icon}.svg');
    }
  }
}
