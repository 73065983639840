// Colors
$fullpage-background: #000;
$fullpage-background-alpha: 0.5;

$button-text-color: #FFF;
$button-text-color-subdued: rgba(255,255,255,.5);
$button-background-normal: #003d7c;
$button-background-hover: #0156b1;
$button-background-active: #cb5700;

$close-button-background-normal: #003d7c;
$close-button-background-hover: #406e9d;
$close-button-background-active: #cb5700;

// Transitions
$transition-delay: 0.3s;
$transition-delay-slow: 2s;

// Components dimensions
$button-height: 2rem;

// https://www.flaticon.com/packs/basic-ui-5
// https://www.flaticon.com/packs/pointers-5
// play/pause in Icons component
$icons: hand-tap;